import React from 'react'


function NotFound() {
    return (
        <div style={{textAlign: 'center', margin: '50px'}}>
            <h1>Sorry Page Not Found </h1>
            <br />
            <h1>🤷🏻</h1>
        </div>

    );
}


export default NotFound;