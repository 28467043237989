import React from 'react';
import ApiData from '../components/ApiData'

function Shows() {

  return (
      <ApiData dataType="tv" cardType="tv"/>
    )
  }


export default Shows;