import drown from "../assets/images/Drown.jpg";
import parallels from "../assets/images/Parallels.jpg";
import mamitas from "../assets/images/Mamitas.jpg";

export const missing = [
    
  { posterImg: drown, title: "Don't Let Me Drown"},

  { posterImg: parallels, title: "Parallels" },

  { posterImg: mamitas, title: "Mamitas" },
];
